import { utdEpicClient } from '@wk/utd-integrated';
import IFrameClient from './utility/IFrameClient';

// to ensure backward compatibility in ACA:
window.epicClient = utdEpicClient();
if (typeof epicClient !== 'undefined') {
    epicClient.initialize();
}

window.udaExplorer = new IFrameClient({
    name: 'UDAExplorer',
    initiateHandshake: 'UDAExplorer.InitiateHandshake',
    debug: false
});
if (typeof udaExplorer !== 'undefined') {
    udaExplorer.initialize();
}