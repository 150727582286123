/* eslint-disable max-len */
import { getWindow } from '_acaSrc/utility/DOM';
export default class IFrameClient {
    constructor(config) {
        const { name, initiateHandshake = 'InitiateHandshake', debug = false } = config;
        this._name = name;
        this._initiateHandshake = initiateHandshake;
        this._debug = debug;

        this._active = false;
        this._token = null;
    }

    isActive() {
        return this._active;
    }

    token() {
        return this._token;
    }

    listener(event) {
        if (event.type === 'message') {
            if (this._debug) {
                console.log(`{CLIENT}: <listener> message type=[${event?.type}] data=[${JSON.stringify(event?.data)}]`);
            }
            this.handleMessage(event.data);
        }
    }

    handleMessage(message) {
        if (this._debug) {
            console.log(`{CLIENT}: [${this._name}] received message [${JSON.stringify(message)}]`);
        }
        for (const type in message){
            switch (type) {
            case 'token':
                this._token = message.token;
                this._active = true;
                break;
            }
        }
    }

    initialize() {
        getWindow().addEventListener('message', this.listener.bind(this), false);
        
        // Delay 1 second to allow parent to initialize
        setTimeout(() => {
            if (this._debug) {
                console.log(`{CLIENT}: [${this._name}] initiating handshake [${this._initiateHandshake}]`);
            }
            this.sendMessage({ 'action': this._initiateHandshake });
        }, 1000);
    }

    sendMessage(message, origin){
        if (typeof origin === 'undefined'){
            origin = '*';
        }

        if (typeof getWindow().postMessage !== 'undefined'){
            if (this._debug) {
                console.log(`{CLIENT}: [${this._name}] sending message=[${JSON.stringify(message)}]`);
            }
            getWindow().parent.postMessage(message, origin);
        }
    }
}